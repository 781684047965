html {
    --font: 'Poppins', sans-serif !important;
    --defaultWhite: #fff;
    --defaultColorOne: #18a7c7;
    --defaultColorTwo: #052657;
    --defaultColorOneLight: #91b1c1;
    --defaultLightBlue: #c9cef9;
    --defaultLightYellow: #fff7d9;
    --defaultYellow: #FFCC00;
    --defaultGrayColor: #71717A;
    --defaultGrayBgColor: #f2f2f2;
    --defaultBlue: #03409D;
    --defaultLightRed: #fde3e3;
    --defaultRed: #C91656;
    --defaultLightGreen: #def6e7;
    --defaultGreen: #36bea6;
    --defaultlightBlack: #4D4D4D;
    --defaultVividBlue: #00A8E0;
    --defaultBlack: #000;
    --defaultlightGray: #C4C4C4;
    --defaultGradientColor: linear-gradient(to left, var(--defaultColorOne), var(--defaultColorTwo));
}

/* ANTD GLOBAL CLASSES */

/* STYLE */
.dashboard_sec1 {
    background: #fff;
    box-shadow: inset 5px 2px 48px -5px #00000070;
    padding: 4rem;
}

.dashboard_sec1 h1 {
    color: var(--defaultBlue);
    font-size: 56px;
    font-weight: 600;
    text-align: center;
}

.card_home {
    background: var(--defaultBlue);
    color: var(--defaultWhite);
    width: 45%;
    padding: 2rem;
    margin: 0 auto
}

.card_home .heading_card {
    border-bottom: 1px solid var(--defaultWhite);
    width: 7rem;
    margin: 0 auto;
    font-weight: 600;
}


.card_home p {
    margin-bottom: 3px;
    font-weight: 600;
}

.card_home .vertical_line {
    border: 1px solid var(--defaultWhite);
    height: 34px;
}

.grids {
    padding: 5rem;
}

.card_white_bg {
    color: var(--defaultBlue);
    background: #F1FBFF;
    box-shadow: inset 0px -8px 14px 0px #00000030;
    width: 45%;
    padding: 2rem;
    margin: 0 auto
}

.card_white_bg .vertical_line {
    border: 1px solid var(--defaultBlue);
    height: 34px;
}
.card_white_bg .heading_card {
    border-bottom: 1px solid var(--defaultBlue);
    width: 7rem;
    margin: 0 auto;
    font-weight: 600;
}
.card_white_bg p {
    margin-bottom: 3px;
    font-weight: 600;
}
.cardstyle {
    position: relative;
    transition: all 1s ease;
    -o-transition: all 1s ease;
    -moz-transition: all 1s ease;
    -webkit-transition: all 1s ease;
    top: 15px;
    cursor: pointer;
}
.contactbutton{
    cursor: pointer;
}

.cardstyle:hover {
    top: 0px;
}
