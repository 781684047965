html {
  --font: 'Poppins', sans-serif !important;
  --defaultWhite: #fff;
  --defaultColorOne: #18a7c7;
  --defaultColorTwo: #052657;
  --defaultColorOneLight: #91b1c1;
  --defaultLightBlue: #c9cef9;
  --defaultLightYellow: #fff7d9;
  --defaultYellow: #FFCC00;
  --defaultGrayColor: #71717A;
  --defaultGrayBgColor: #f2f2f2;
  --defaultBlue: #03409D;
  --defaultLightRed: #fde3e3;
  --defaultRed: #C91656;
  --defaultLightGreen: #def6e7;
  --defaultGreen: #36bea6;
  --defaultlightBlack: #4D4D4D;
  --defaultVividBlue: #00A8E0;
  --defaultBlack: #000;
  --defaultlightGray: #C4C4C4;
  --defaultGradientColor: linear-gradient(to left, var(--defaultColorOne), var(--defaultColorTwo));
}

/* Style the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

/* Style the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: gray;
  border-radius: 2px;
}

/* Set the width of the scrollbar */
::-webkit-scrollbar {
  width: 2px;
}

.common_Button {
  border-radius: 20px;
  background: var(--defaultColorOne);
  color: var(--defaultWhite);
  padding: 1.35rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.lightBlack {
  color: var(--defaultlightBlack);
}

.lightGray {
  color: var(--defaultlightGray);
}

.msgButton {
  font-weight: bold;
  color: var(--defaultWhite);
  background-color: var(--defaultVividBlue);
}

.detailTab {
  border-radius: 0px;
  color: var(--defaultBlack);
  border-bottom: 2px solid var(--defaultlightBlack);
}

.commonCard {
  padding: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.accountInput {
  border: 1px solid var(--defaultBlack);
  min-height: 2.3rem;
  border-radius: 0px !important;
  -webkit-border-radius: 0px !important;
  -moz-border-radius: 0px !important;
  -ms-border-radius: 0px !important;
  -o-border-radius: 0px !important;
}

.accountSaveBtn {
  background: var(--defaultBlue);
  border-color: 1px solid var(--defaultBlue);
  border-radius: 100px;
  color: var(--defaultWhite);
}

.blueBorderButton {
  border: 1px solid var(--defaultBlue) !important;
  color: var(--defaultBlue);
  font-weight: bold;
}

.select .ant-select-selector {
  border: 2px solid var(--defaultBlue) !important;
  color: var(--defaultBlue);
  font-weight: bold;
  border-radius: 5rem;
  -webkit-border-radius: 5rem;
  -moz-border-radius: 5rem;
  -ms-border-radius: 5rem;
  -o-border-radius: 5rem;
}

.blueText {
  color: var(--defaultBlue);
}

.greenButton {
  border: 1px solid var(--defaultGreen) !important;
  color: var(--defaultWhite);
  background-color: var(--defaultGreen);
  font-weight: bold;
}

.redButton {
  border: 1px solid var(--defaultRed) !important;
  color: var(--defaultWhite);
  background-color: var(--defaultRed);
  font-weight: bold;
}

.ant-table-thead .ant-table-cell {
  background-color: var(--defaultWhite) !important;
  color: var(--defaultlightBlack) !important;
}

.cardLocation {
  padding: 0.5rem;
  border: 2px solid var(--defaultBlue) !important;
  border-radius: 0.5rem;
  -webkit-border-radius: 0.5rem;
  -moz-border-radius: 0.5rem;
  -ms-border-radius: 0.5rem;
  -o-border-radius: 0.5rem;
  min-width: 12rem;
}

.cardLocationTitle {
  font-weight: bold;
  color: var(--defaultBlue);
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  z-index: 1;
  color: var(--defaultBlue) !important;
  background: #ffffff;
  border: 1px solid var(--defaultBlue) !important;
  font-weight: bold;
}
.eventSaveBtn{
  background: var(--defaultColorOne);
  border-color: 1px solid var(--defaultColorOne);
  color: var(--defaultWhite);
}